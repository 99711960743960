import Link from 'gatsby-link'
import React, { Component } from "react"
import logoImage from "../../images/triazpr-logo-negative.svg"

import "./styles.scss"

class Logo extends Component {

    render() {
        return (
            <div className="logo">
                <Link to="/" title="Zurück zur Startseite"><img src={logoImage} alt="TriazPR" width="107" height="60" /></Link>
            </div>
        )
    }
}  
  
export default Logo