import React, { Component } from "react"
import Link from "gatsby-link"
import moment from "moment-timezone"
import SbEditable from "storyblok-react"

import "./styles.scss"
import logo from "../../images/triazpr-logo-positive.svg"

class Footer extends Component {
    constructor(props) {
        super(props)
        this.settings = props.settings
    }

    render() {
        const today = new moment().tz('Europe/Berlin')

        return (
            <footer className="footer">
                <div className="container">
                    <div className="footer-navigation">
                        <div className="column-1">
                            <img className="logo" src={logo} width="143" height="80" alt="TriazPR Logo" />
                        </div>
                        <div className="column-2">
                            <h4>{this.settings.footer_navi_title_1}</h4>
                            <nav className="footer-menu" role="navigation" aria-label="Footer-Menü 1">
                                <ul>
                                {this.settings && this.settings.content.footer_navi_1.map((navitem, index) => (
                                    <SbEditable content={navitem} key={navitem._uid}>
                                        <li key={index}>
                                            {typeof navitem.link.anchor !== "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}#${navitem.link.anchor}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                            {typeof navitem.link.anchor === "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                        </li>
                                    </SbEditable>
                                ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="column-3">
                            <h4>{this.settings.footer_navi_title_2}</h4>
                            <nav className="footer-menu" role="navigation" aria-label="Footer-Menü 2">
                                <ul>
                                {this.settings && this.settings.content.footer_navi_2.map((navitem, index) => (
                                    <SbEditable content={navitem} key={navitem._uid}>
                                        <li key={index}>
                                            {typeof navitem.link.anchor !== "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}#${navitem.link.anchor}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                            {typeof navitem.link.anchor === "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                        </li>
                                    </SbEditable>
                                ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="column-4">
                            <nav className="footer-menu social-media" role="navigation" aria-label="Social Media Profile">
                                <ul>
                                    <li>
                                        <a href={this.settings.linkedin_link} className="linkedin-link" title="LinkedIn-Profil öffnen" target="_blank" rel="noreferrer">LinkedIn</a>
                                    </li>
                                    <li>
                                        <a href={this.settings.twitter_link} className="twitter-link" title="Twitter-Profil öffnen" target="_blank" rel="noreferrer">Twitter</a>
                                    </li>
                                    <li>
                                        <a href={this.settings.instagram_link} className="instagram-link" title="Instagram-Profil öffnen" target="_blank" rel="noreferrer">Instagram</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="copyright">
                        &copy; {today.format('YYYY')} TRIAZ PR - Stephanie Pennekamp
                    </div>
                </div>
            </footer>
          )
    }
}

export default Footer