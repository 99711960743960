import React, { Component } from "react"
import Link from "gatsby-link"
import SbEditable from "storyblok-react"
import Logo from "../Logo"

import "./styles.scss"

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.settings = props.settings
        this.location = props.location
        this.state = {
            isOpen: "not-open"
        }

        this.path = null

        if (typeof props.location !== "undefined") {
            const folders = props.location.pathname.split("/")
            this.path = `${ folders[1] }/${ folders[2] }`
        }

        this.toggleMenu = this.toggleMenu.bind(this)
    }

    toggleMenu() {
        if (this.state.isOpen === "not-open") {
            this.setState({ isOpen: "is-open" })
        } else {
            this.setState({ isOpen: "not-open" })
        }
    }

    render() {
        const menuLength = this.settings.content.main_navi.length;
        const menuLengthLeft = Math.round(menuLength/2)

        return (
            <div className="navigation-wrapper">
                <nav className={`main-navigation ${ this.state.isOpen }`} role="navigation" aria-label="Hauptnavigation">
                    <div className="close-trigger" onClick={this.toggleMenu} role="button" onKeyDown={this.toggleMenu} tabIndex={0}>Navigation schließen</div>
                    <ul>
                    {this.settings && this.settings.content.main_navi.map((navitem, index) => (
                        <SbEditable content={navitem} key={navitem._uid}>
                            {menuLengthLeft === index+1 &&
                                <>
                                    <li key={index}>
                                        {navitem.link.cached_url === this.path &&
                                        <Link className="active" to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                        {navitem.name}
                                        </Link>
                                        }
                                        {navitem.link.cached_url !== this.path &&
                                        <div>
                                            {typeof navitem.link.anchor !== "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}#${navitem.link.anchor}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                            {typeof navitem.link.anchor === "undefined" &&
                                            <Link to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                                {navitem.name}
                                            </Link>
                                            }
                                        </div>
                                        }
                                    </li>
                                    <li className="logo"><Logo /></li>
                                </>
                            }
                            {menuLengthLeft !== index+1 &&
                                <li key={index}>
                                    {navitem.link.cached_url === this.path &&
                                    <Link className="active" to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                    {navitem.name}
                                    </Link>
                                    }
                                    {navitem.link.cached_url !== this.path &&
                                    <div>
                                        {typeof navitem.link.anchor !== "undefined" &&
                                        <Link to={`/${navitem.link.cached_url.replace("home", "")}#${navitem.link.anchor}`} prefetch="true">
                                            {navitem.name}
                                        </Link>
                                        }
                                        {typeof navitem.link.anchor === "undefined" &&
                                        <Link to={`/${navitem.link.cached_url.replace("home", "")}`} prefetch="true">
                                            {navitem.name}
                                        </Link>
                                        }
                                    </div>
                                    }
                                </li>
                            }
                        </SbEditable>
                    ))}
                    </ul>   
                </nav>
                <div className={`menu-toggle ${ this.state.isOpen }`} onClick={this.toggleMenu} role="button" onKeyDown={this.toggleMenu} tabIndex={0}>
                    <div className="line-one line"></div>
                    <div className="line-two line"></div>
                    <div className="line-three line"></div>
                </div>
                <div className="mobile-logo">
                    <Logo />
                </div>
            </div>
          )
    }
}

export default Navigation