import React, { Component }  from "react"
import Navigation from "../Navigation/index"

import "./styles.scss"

class Header extends Component {
    constructor(props) {
        super(props)
        this.settings = props.settings
        this.location = props.location
    }

    render() {
        return (
            <header>
                <div className="main-header">
                    <div className="container">
                        <Navigation settings={this.settings} location={this.location} />
                    </div>
                </div>        
            </header>
        )
    }
}

export default Header
